const breeds = [
  'affenpinscher',
  'african',
  'airedale',
  'akita',
  'appenzeller',
  'australian shepherd',
  'basenji',
  'beagle',
  'bluetick',
  'borzoi',
  'bouvier',
  'boxer',
  'brabancon',
  'briard',
  'norwegian buhund',
  'boston bulldog',
  'english bulldog',
  'french bulldog',
  'staffie',
  'cairn',
  'australian cattledog',
  'chihuahua',
  'chow',
  'clumber',
  'cockapoo',
  'collie',
  'border collie',
  'coonhound',
  'corgi',
  'cardigan corgi',
  'cotondetulear',
  'dachshund',
  'dalmatian',
  'great dane',
  'scottish deerhound',
  'dhole',
  'dingo',
  'doberman',
  'norwegian elkhound',
  'entlebucher',
  'eskimo',
  'finnish lapphund',
  'frise bichon',
  'german shepherd',
  'greyhound',
  'italian greyhound',
  'groenendael',
  'havanese',
  'afghan hound',
  'basset hound',
  'blood hound',
  'english hound',
  'ibizan hound',
  'plott hound',
  'walker hound',
  'husky',
  'keeshond',
  'kelpie',
  'komondor',
  'kuvasz',
  'labrador',
  'leonberg',
  'lhasa',
  'malamute',
  'malinois',
  'maltese',
  'bull mastiff',
  'english mastiff',
  'mtibetan astiff',
  'mexican hairless',
  'mixed breed',
  'bernese mountain dog',
  'swiss mountain dog',
  'newfoundland',
  'otterhound',
  'caucasian ovcharka',
  'papillon',
  'pekinese',
  'pembroke',
  'miniature pinscher',
  'pitbull',
  'german pointer',
  'longhair german pointer',
  'pomeranian',
  'miniature poodle',
  'poodle',
  'toy poodle',
  'pug',
  'puggle',
  'pyrenees',
  'redbone',
  'chesapeake retriever',
  'curly retriever',
  'flatcoated retriever',
  'golden retriever',
  'rhodesian ridgeback',
  'rottweiler',
  'saluki',
  'samoyed',
  'schipperke',
  'schnauzer',
  'giant schnauzer',
  'miniature schnauzer',
  'english setter',
  'gordon setter',
  'irish setter',
  'english sheepdog',
  'shetland sheepdog',
  'shiba',
  'shihtzu',
  'blenheim spaniel',
  'brittany spaniel',
  'cocker spaniel',
  'irish spaniel',
  'japanese spaniel',
  'sussex spaniel',
  'welsh spaniel',
  'english springer',
  'saint bernard',
  'american terrier',
  'australian terrier',
  'bedlington terrier',
  'border terrier',
  'dandie terrier',
  'fox terrier',
  'irish terrier',
  'kerryblue terrier',
  'lakeland terrier',
  'norfolk terrier',
  'norwich terrier',
  'patterdale terrier',
  'russell terrier',
  'scottish terrier',
  'sealyham terrier',
  'silky terrier',
  'tibetan terrier',
  'toy terrier',
  'westhighland terrier',
  'wheaten terrier',
  'yorkshire terrier',
  'vizsla',
  'spanish waterdog',
  'weimaraner',
  'whippet',
  'irish wolfhound'
];

export default breeds;
